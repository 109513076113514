import React, { useCallback, useContext, useEffect, useState } from 'react';
import './empresa-dropdown.css';
import EmpresaService, { EmpresaOutput } from '../../services/EmpresaService';
import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';

export interface EmpresaDropdownProps {
  disabled: boolean,
  onChange?: (empresa: EmpresaOutput | null) => void
}

const EmpresaDropdown = ({ disabled, onChange }: EmpresaDropdownProps) => {
  const { sessao } = useContext(SessaoUsuarioContext);
  const [empresas, setEmpresas] = useState<EmpresaOutput[]>([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState<EmpresaOutput | null>(null);

  useEffect(() => {
    onChange?.(empresaSelecionada);
  }, [empresaSelecionada, onChange]);

  useEffect(() => {
    (async () => {
      if (sessao) {
        const output = await EmpresaService.obterEmpresas();
        setEmpresas(output.content.empresas);
        if (output.content.empresas.length) {
          setEmpresaSelecionada(output.content.empresas[0]);
        }
      }
    })();
  }, [sessao]);

  const alteracaoEmpresaHandler = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const empresaId = event.target.value;
    const empresa = empresas.find((c) => c.id === empresaId);
    setEmpresaSelecionada(empresa || null);
  }, [empresas]);

  return (
    <div>
      <label className="company-label" htmlFor="company">Empresa: </label>
      <select id="company" className="company-select" value={empresaSelecionada?.id || ''} disabled={disabled} onChange={alteracaoEmpresaHandler}>
        {empresas.map((company) => (
          <option key={company.id} value={company.id}>
            {company.nome}
          </option>
        ))}
      </select>
    </div>
  );
};

export default EmpresaDropdown;