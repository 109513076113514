import { useCallback, useState } from 'react';
import UsuarioService, { UsuarioInput, getCategoriaIndexByName } from '../../../services/UsuarioService';
import UsuarioForm from '../usuario-formulario/usuario-formulario';
import ErroDeNegocio from '../../../errors/ErroDeNegocio';
import useAppFeedback from '../../../hooks/use-app-feedback';

import './usuario-edicao.css'

interface UsuarioEdicaoProps {
  usuarioParaAtualizar: UsuarioInput;
  clickCancelar?: () => void;
}

const UsuarioEdicao = ({ usuarioParaAtualizar, clickCancelar }: UsuarioEdicaoProps) => {

  const [loading, setLoading] = useState(false);
  const { adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks } = useAppFeedback();

  const atualizarUsuario = useCallback((async (usuario: UsuarioInput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true);

    try {
      usuario.categoria = getCategoriaIndexByName(usuario.categoria)
      await UsuarioService.salvarUsuario(usuario);
      adicionarFeedbackDeSucesso("Usuario atualizado com sucesso!");
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
    }
    setLoading(false)

    return false;
  }), [adicionarFeedbackDeErro, adicionarFeedbackDeSucesso, limparFeedbacks]);

  return (
    <>
      <UsuarioForm
        classes='usuario-edicao'
        usuarioInput={usuarioParaAtualizar}
        loading={loading}
        titulo={'Alterar Usuário'}
        acao={{
          onClickAcao: atualizarUsuario,
          texto: 'Salvar'
        }}
        cancelarAcao={{
          onClickCancelaAcao: clickCancelar,
          texto: "Cancelar"
        }} />
    </>
  );
}

export default UsuarioEdicao;

