import { useState } from "react";
import './upload.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import Button from "../button/button";

interface UploadProps {
  onImagesChange: (images: string[]) => void;
};
const Upload = ({ onImagesChange }: UploadProps) => {
  const [arrayImagens, setArrayImagnes] = useState<string[]>([])

  const handleImageUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string')
          atualizarImagens(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const atualizarImagens = (value: any) => {
    setArrayImagnes(arrayImagens => [...arrayImagens, value])
    onImagesChange([...arrayImagens, value]);
  }

  const removerTotosOsAnexos = () => {
    setArrayImagnes([]);
    onImagesChange([]);
  }

  const removerAnexo = (value: any) => {
    setArrayImagnes(arrayImagens => arrayImagens.filter((_, i) => i !== value));
    onImagesChange(arrayImagens.filter((_, i) => i !== value));
  }

  return (
    <div>
      {arrayImagens.length > 0 && <div className="upload">
        {arrayImagens.map((src, index) => (
          <div key={index} className="agrupa-imagens">
            <FontAwesomeIcon key={index} className='icon-svg' icon={faTrashCan} />
            <img src={src} alt="Preview" style={{ width: '100px', marginLeft: '10px' }} onClick={() => removerAnexo(index)} />
          </div>
        ))}
      </div>}
      <label htmlFor="file-upload" className="button-upload">
        <FontAwesomeIcon icon={faFileArrowUp} />
      </label>
      <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} />
      <Button classes="button-upload"
        text={<><FontAwesomeIcon icon={faTrashCan} /></>} onClick={() => removerTotosOsAnexos()} />
    </div>
  )

}

export default Upload;