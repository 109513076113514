import Markdown from 'markdown-to-jsx'
import { useEffect, useState } from "react"

interface markdownprops {
  urlArquivo: string
}
const MarkDown = ({ urlArquivo }: markdownprops) => {
  const [postContent, setPostContent] = useState("")

  useEffect(() => {
    fetch(urlArquivo)
      .then(res => res.text())
      .then(res => setPostContent(res))
      .catch(err => console.log(err));
  })

  return (
    <div>
      <Markdown>
        {postContent}
      </Markdown>
    </div>
  )
}

export default MarkDown;