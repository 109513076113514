import { CSSProperties, TableHTMLAttributes } from "react";
import { TableHeader } from '../table.types';
import './header.css';

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  headers: (string | TableHeader)[],
  columnStyles?: { [key: string]: CSSProperties }
}

const Header = ({ headers, columnStyles }: TableProps) => {
  return (
    <>
      <thead>
        <tr>
          {
            headers?.map((value, index) => {
              const valueIsString = typeof value === 'string';
              const property = valueIsString ? value : (value as TableHeader).property;
              const name = valueIsString ? value : (value as TableHeader).name;
              return <th key={index} style={columnStyles?.[property]}>{name}</th>
            })
          }
        </tr>
      </thead>
    </>
  );
}
export default Header;