import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';
import useFeedback from '../../hooks/use-feedback';
import Feedback from '../../components/feedback/feedback';
import './Home.css';
import { Modulos } from '../../models/Modulos';
import NotificacaoDeAcessoNegadoAoModulo from '../shared/notificacao-acesso-negado-modulo';

const Home = () => {
  const navigate = useNavigate();
  const { sessao } = useContext(SessaoUsuarioContext);
  const [notificacao, setNotificacao] = useState<ReactNode>(null)
  const { feedbacks, adicionarFeedbackDeErro, limparFeedbacks } = useFeedback();
  const temLicencaFortesPessoal = useMemo(() => {
    return sessao?.temLicencaFortesPessoal && sessao.modulosLiberados?.some(m => m.nome === Modulos.FORTES_PESSOAL);
  }, [sessao]);
  const temLicencaFortesRh = useMemo(() => {
    return !!sessao?.temLicencaFortesRh && sessao.modulosLiberados?.some(m => m.nome === Modulos.FORTES_RH);
  }, [sessao]);
  // const temLicencaFortesFinanceiro = useMemo(() => {
  //   return !!sessao?.temLicencaFortesFinanceiro && sessao.modulosLiberados?.some(m => m.nome === Modulos.FORTES_FINANCEIRO);
  // }, [sessao]);

  const encaminhaParaModulo = useCallback((modulo: string) => {
    limparFeedbacks()
    if (!sessao?.modulosLiberados.length) {
      adicionarFeedbackDeErro("Você não tem acesso aos modulos")
      return
    }

    if (!sessao?.modulosLiberados.find(x => x.nome.toLowerCase() === modulo)) {
      adicionarFeedbackDeErro("Você não tem acesso a esse módulo");
      return
    }

    if (sessao?.modulosLiberados.find(x => x.nome.toLowerCase() === modulo && x.status === false)) {
      adicionarFeedbackDeErro("Você não tem acesso a esse módulo")
      return
    }

    navigate(`/modulo/${modulo}`);
  }, [adicionarFeedbackDeErro, limparFeedbacks, navigate, sessao?.modulosLiberados])

  const exibeNotificacao = () => {
    if (notificacao) {
      setNotificacao(null)
      return
    }
    setNotificacao(<NotificacaoDeAcessoNegadoAoModulo />)
  }

  return (
    <>
      <div className="content-modulo">
        <Feedback feedbacks={feedbacks} />
        <h1>Selecione um módulo para começar!</h1>
        {notificacao}
        <div className="content-modulo-row"
          style={{ justifyContent: 'center' }}>
          <div className={`content-modulo-collum${!temLicencaFortesPessoal ? '-disabled' : ''}`} id='pessoal'
            onClick={() => temLicencaFortesPessoal ? encaminhaParaModulo("pessoal") : exibeNotificacao()}>
            <div className="content-modulo-row">
              <div className="content-collum" style={{ paddingTop: '30px' }}>
                <span>MÓDULO</span>
                <label>Pessoal</label>
              </div>
              <div className="content-collum">
                <div className='img'></div>
              </div>
            </div>
          </div>
          <div className={`content-modulo-collum${!temLicencaFortesRh ? '-disabled' : ''}`} id='rh'
            onClick={() => temLicencaFortesRh ? encaminhaParaModulo("rh") : exibeNotificacao()}>
            <div className="content-modulo-row">
              <div className="content-collum" style={{ paddingTop: '30px' }}>
                <span>MÓDULO</span>
                <label>RH</label>
              </div>
              <div className="content-collum"
                style={{ paddingLeft: '80px' }}>
                <div className='img'></div>
              </div>
            </div>
          </div>
          {/*<div className={`content-modulo-collum${!temLicencaFortesFinanceiro ? '-disabled' : ''}`} id='financeiro'
            onClick={() => temLicencaFortesFinanceiro ? encaminhaParaModulo("financeiro") : exibeNotificacao()}>
            <div className="content-modulo-row">
              <div className="content-collum" style={{ paddingTop: '30px' }}>
                <span>MÓDULO</span>
                <label>Financeiro</label>
              </div>
              <div className="content-collum">
                <div className='img'></div>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </>
  );
}
export default Home;