import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonHTMLAttributes, ReactElement, useCallback, useState } from "react";
import './action.css';
import Modal from "../modal/modal";
import Button from "../button/button";

interface ActionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconDefinition;
  title?: string;
  value?: any;
  confirm?: boolean;
  confirmMessage?: string | ReactElement;
  onRowClick?: (value: any) => Promise<boolean>;
}

const Action = ({ value, title, icon, confirm, confirmMessage, onRowClick }: ActionProps) => {
  const [isConfirmationOpened, setIsConfirmationOpened] = useState<boolean>(false);
  const onClickHandler = useCallback(() => {
    if (confirm) {
      setIsConfirmationOpened(true);
      return;
    }

    return onRowClick && onRowClick(value);
  }, [value, confirm, onRowClick]);

  return (
    <>
      <button
        title={title}
        className='action__button'
        onClick={onClickHandler}>
        <FontAwesomeIcon className='action__button--img' style={{ width: "23px" }} icon={icon} />
      </button>
      <Modal isOpened={isConfirmationOpened}>
        <div style={{ textAlign: 'center' }}>
          <div>{confirmMessage || <>Tem certeza que deseja executar essa operação?</>}</div>
          <div style={{ marginTop: '15px' }}>
            <Button style={{ margin: '5px' }} classes='formulario__button' text='Confirmar'
              onClick={async () => onRowClick && await onRowClick(value) && setIsConfirmationOpened(false)} />
            <Button style={{ margin: '5px' }} classes='formulario__button' text='Cancelar'
              onClick={() => setIsConfirmationOpened(false)} />
          </div>
        </div>
      </Modal>
    </>
  )
}
export default Action;