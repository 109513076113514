import { CSSProperties, TableHTMLAttributes } from "react";
import './body.css';
import { TableData, TableHeader } from "../table.types";

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  headers?: (string | TableHeader)[]
  data: TableData[],
  onRowClick?: (value: any) => void,
  actions?: (value: TableData) => React.ReactElement;
  rowStyle?: CSSProperties
}

const Body = ({ data, rowStyle, onRowClick, headers, actions }: TableProps) => {
  return (
    <tbody className="table-body">
      {
        data?.map((value, index) => {
          return (
            <tr
              key={index}
              style={rowStyle}
              onClick={() => onRowClick && onRowClick(value)}>
              {headers
                ? headers.map((header, idx) => {
                  const headerIsString = typeof header === 'string';
                  const property = headerIsString ? header : (header as TableHeader).property;
                  const getter = headerIsString ? null : (header as TableHeader).getter;
                  const valueToDisplay = getter ? getter(value) : value[property.toLowerCase() as keyof typeof value];
                  return (valueToDisplay && <td key={idx}>{valueToDisplay}</td>);
                })
                : Object.values(value).map((val, ix) => {
                  return <td key={ix}>{val}</td>
                })}
              {actions && <td>{actions?.(value)}</td>}
            </tr>
          )
        })
      }
    </tbody>
  );
}
export default Body;