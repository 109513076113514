import { Tickets } from '../../../models/Ticket';
import Button from '../../../components/button/button';
import './ticketDetalhes.css';
import SessaoUsuarioContext from '../../../contexts/SessaoUsuarioContext';
import { useContext } from 'react';

interface DetalhesProps {
  conteudo: Tickets | undefined,
  clickDetalhes?: () => void;
}

const TicketDetalhes = ({ conteudo, clickDetalhes }: DetalhesProps) => {
  const { sessao } = useContext(SessaoUsuarioContext);
  return (
    <>
      <div className='content-detalhes'>
        <div className='grid-inline-detalhes'>
          <label className='labeDetalhes' style={{ fontWeight: 'bold' }}>Ticket : {conteudo?.protocolo}</label><br />
        </div>
        <div className='grid-inline-detalhes'>
          <label style={{ color: 'black', fontWeight: 700, fontSize: '20px' }}>{conteudo?.titulo}</label><br />
        </div>
        <div className='grid-inline-detalhes'>
          <div className='grid-collum' style={{ margin: '0 20px 20px 0' }}>
            <label className='labeDetalhes'>
              <span>CPF/CNPJ: </span>{sessao?.licenciadoCnpj}
            </label>
            <label className='labeDetalhes'>
              <span>Categoria: </span>{conteudo?.categoria}
            </label>
            <label className='labeDetalhes'>
              <span>Urgencia: </span>{conteudo?.urgencia}
            </label>
          </div>
          <div className='grid-collum' style={{ margin: '0 20px 20px 0' }}>
            <label className='labeDetalhes'>
              <span>Solicitante: </span>{conteudo?.nomeSolicitante}
            </label>
            <label className='labeDetalhes'>
              <span>Equipe Responsavel: </span>{conteudo?.equipeResponsavel}
            </label>
            <label className='labeDetalhes'>
              <span>Status: </span>{conteudo?.status}
            </label>
          </div>
          <div className='grid-collum' style={{ margin: '0 20px 20px 0', background: '#F7F8F9', padding: '8px', borderRadius: '8px' }}>
            <label className='labeDetalhes'>
              <span>Abertura: </span>{conteudo?.abertura}
            </label>
            <label className='labeDetalhes'>
              <span>Previsão: </span>{conteudo?.previsaoSolucao}
            </label>
            <label className='labeDetalhes'>
              <span>Encerramento: </span>{conteudo?.dataEncerramento}
            </label>
          </div>
        </div>
        <div className='ticket__detalhes'>
          <div className='grid-inline-detalhes'>
            <span>DETALHAMENTO</span>
          </div>
          <div className='grid-inline-detalhes'>
            <div className='grid-collum'>
              <span>Qual problema você deseja resolver?</span>
              <label className='labeDetalhes'>{conteudo?.problema}</label>
            </div>
          </div>
          <div className='grid-inline-detalhes'>
            <div className='grid-collum'>
              <span>Alguma proposta de solução para o problema ?</span>
              <label className='labeDetalhes'>{conteudo?.propostaDeSolucao}</label>
            </div>
          </div>
          <div className='grid-inline-detalhes'>
            <div className='grid-collum'>
              <span>Quanto custa (financeira ou processualmente)para seu negocio se não fizermos isso ?</span>
              <label className='labeDetalhes'>{conteudo?.embasamento}</label>
            </div>
          </div>
          <div className='grid-inline-detalhes'>
            <div className='grid-collum'>
              <span>Qual o embasamento (anexar legislação, ACT/CCT e demais documentos)?</span>
              <label className='labeDetalhes'>{conteudo?.custoSeNaoImplementado}</label>
            </div>
          </div>
        </div>
        <div className='grid-inline-detalhes' style={{ width: '100%', justifyContent: 'end' }}>
          <Button text='Voltar' classes='submit-button' onClick={() => clickDetalhes && clickDetalhes()}></Button>
        </div>
      </div>
    </>
  )
}

export default TicketDetalhes;