import { ReactNode, TableHTMLAttributes } from "react";
import './table.css';

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  children: ReactNode
}

const Table = ({ children, ...rest }: TableProps) => {
  return (
    <>
      <table
        className="table-default"
        {...rest}>
        {children}
      </table>
    </>
  );
}

export default Table;