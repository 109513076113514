
import Button from "../../components/button/button";
import Voltar from "../../components/voltar/voltar";
import { Tickets } from "../../models/Ticket";
import { useNavigate } from "react-router-dom";
import './ticket.css';
import { Table } from "../../components/table";
import Modal from "../../components/modal/modal";
import { useCallback, useEffect, useState } from "react";
import TicketDetalhes from "./TicketDetalhes/TicketDetalhes";
import MovideskService from "../../services/MovideskService";
import moment from 'moment';
import Spinner from "../../components/spinner/spinner";
import useFeedback from "../../hooks/use-feedback";
import Feedback from "../../components/feedback/feedback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faPlus } from "@fortawesome/free-solid-svg-icons";

const Ticket = () => {

  const ArrayHeader = ["Chamado", "Titulo", "Abertura", "Previsão", "Encerramento", "Status"]
  const navigate = useNavigate();
  const CriaNovoTicket = () => navigate('/ticket/novo');
  const { feedbacks, adicionarFeedbackDeErro } = useFeedback();
  const [loading, setLoading] = useState(false)
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [obtemTicket, setObtemTicket] = useState<Tickets>();
  const [arrayTicket, setArrayTicket] = useState<Tickets[] | null>(null);
  const [arrayBody, setTicketBody] = useState<TicketBody[]>([]);

  const obtemTicketDaLista = (ticketBody: TicketBody) => {
    var tieckt = arrayTicket?.find(x => x.protocolo === ticketBody.chamado)
    setObtemTicket(tieckt)
    setIsOpened(true)
  }

  const obtemListaDeSolicitacoesCallback = useCallback(async (): Promise<any> => {
    setLoading(true)
    var solicitacoes = await MovideskService.ObtemListaDeSolicitacao();
    const arraySolicitacoes = solicitacoes['content']['solicitacoes'];
    if (arraySolicitacoes.length === 0) {
      setArrayTicket([])
      setLoading(false)
      return
    }

    const arrayTicketFormatado = arraySolicitacoes.map((solicitacao: Tickets) => {
      return {
        cnpjLicenciado : solicitacao.cnpjLicenciado,
        protocolo: solicitacao.protocolo,
        chamado: solicitacao.protocolo,
        titulo: solicitacao.titulo,
        abertura: moment(solicitacao.abertura).format("DD/MM/YYYY"),
        previsaoSolucao: solicitacao.previsaoSolucao
          ? moment(solicitacao.previsaoSolucao).format("DD/MM/YYYY")
          : solicitacao.previsaoSolucao,
        dataEncerramento: solicitacao.dataEncerramento
          ? moment(solicitacao.dataEncerramento).format("DD/MM/YYYY")
          : solicitacao.dataEncerramento,
        status: solicitacao.status,
        problema: solicitacao.problema,
        propostaDeSolucao: solicitacao.propostaDeSolucao,
        custoSeNaoImplementado: solicitacao.custoSeNaoImplementado,
        embasamento: solicitacao.embasamento,
        equipeResponsavel: solicitacao.equipeResponsavel,
        nomeSolicitante: solicitacao.nomeSolicitante,
        categoria: solicitacao.categoria,
        urgencia: solicitacao.urgencia,
        modulo: solicitacao.modulo
      };
    });
    const arrayParaExibicao = arrayTicketFormatado.map((solicitacao: Tickets) => {
      return {
        chamado: solicitacao.protocolo,
        titulo: solicitacao.titulo,
        abertura: solicitacao.abertura,
        previsaoSolucao: solicitacao.previsaoSolucao,
        dataEncerramento: solicitacao.dataEncerramento,
        status: solicitacao.status
      }
    });
    setTicketBody(arrayParaExibicao);
    setArrayTicket(arrayTicketFormatado);
    setLoading(false)
  }, []);

  const sincronizarTicketsCallback = useCallback(async (): Promise<any> => {
    setLoading(true)
    try {
      await MovideskService.ReprocessaSugestoes();
      obtemListaDeSolicitacoesCallback();
    } catch (error) {
      setLoading(false)
      adicionarFeedbackDeErro((error as Error).message);
    }
    setLoading(false)
  }, [obtemListaDeSolicitacoesCallback, adicionarFeedbackDeErro]);

  useEffect(() => {
    if (!arrayTicket) {
      obtemListaDeSolicitacoesCallback();
    }

  }, [obtemListaDeSolicitacoesCallback, arrayTicket]);

  const CloseModal = () => {
    setIsOpened(false)
    setObtemTicket(undefined)
  }

  return (
    <>
      <h1 className="top-title"><Voltar />Sugestão de Melhorias</h1>
      <div className="container-ticket">
        <div className="row">
          <Button classes="submit-button" style={{ margin: '5px' }}
            text={<><FontAwesomeIcon icon={faPlus} /> Inserir</>}
            onClick={() => CriaNovoTicket()}></Button>
          <Button classes="submit-button" style={{ margin: '5px' }}
            text={<><FontAwesomeIcon icon={faArrowsRotate} /> Sincronizar</>}
            onClick={() => { sincronizarTicketsCallback() }}></Button>
        </div>
        <div style={{ overflowY: 'auto', margin: '15px 0' }}>
          <Table.Root>
            <Table.Header headers={ArrayHeader}></Table.Header>
            {!loading  && <Table.Body data={arrayBody} rowStyle={{ cursor: 'pointer' }} onRowClick={obtemTicketDaLista}></Table.Body>}
          </Table.Root>
        </div>
        {<Modal isOpened={isOpened}>
          <TicketDetalhes conteudo={obtemTicket} clickDetalhes={CloseModal} />
        </Modal>}
        {loading && <div className='loading'>
          <Spinner size={170} />
        </div>}
        <Feedback feedbacks={feedbacks} />
      </div>
    </>
  )
}

export default Ticket;

export interface TicketBody {
  chamado: string
  titulo: string
  abertura: string
  previsao: string
  encerramento: string
  status: string
}
