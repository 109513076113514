import moment from "moment";

const data = moment();
const dataAtual = data.format('DD/MM/YYYY');

export interface Tickets {
  protocolo : string | null
  chamado: string | null
  titulo: string
  cnpjLicenciado:string,
  categoria?:string,
  urgencia:string,
  solicitante:string,
  status : string,
  abertura: string
  previsaoSolucao : string
  dataEncerramento: string
  problema: string,
  propostaDeSolucao: string,
  custoSeNaoImplementado: string,
  embasamento: string,
  equipeResponsavel: string,
  nomeSolicitante:string,
  tipo: number,
  imagens:string[],
  modulo: number
}

export enum Equipe {
  Default = 'Produto RH, Ponto e Colabore',
  Pessoal = 'Produto Pessoal',
}

export enum Status {
  Default = '3 - Aguardando',
  Pessoal = 'Aguardando Priorizacao',
}

export enum Justificativa {
  Default = 'Análise Desenvolvimento',
  Pessoal = 'A Analisar',
}

const InicializaTicket = () => {
  return {
    tipo: 2,
    protocolo: '',
    chamado: '',
    titulo: '',
    solicitanteId: null,
    problema: '',
    propostaDeSolucao: '',
    custoSeNaoImplementado: '',
    embasamento: '',
    categoria: 'Solicitação',
    urgencia: 'Média',
    status: Status.Default,
    abertura: dataAtual.toString(),
    equipeResponsavel: Equipe.Default,
    justificativa: Justificativa.Default,
    solicitante: null,
    imagens:[""],
    modulo:0
  }
}

export default InicializaTicket;