import { useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../../contexts/AppContext';

import { Link, useParams } from 'react-router-dom';
import Spinner from '../../components/spinner/spinner';
import InfoNovaVersao from '../../components/info-nova-versao/info-nova-versao';
import { useNavigate } from 'react-router-dom';
import { Painel } from '../../models/Painel';
import { Modulos } from '../../helpers/constantes/const-modulo';
import Voltar from '../../components/voltar/voltar';
import './lista-de-paineis.css';
import NotificacaoDeAcessoNegadoAoModulo from '../shared/notificacao-acesso-negado-modulo';
import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';

const paineisPessoal = ["Dados Demográficos", "Perfil Profissional", "Absenteísmo", "Rotatividade", "Custos da Empresa", "Gastos da Empresa", "SST"];
const paineisRh = ["Desenvolvimento de Talentos", "Recrutamento e Seleção"];

const ListaDePaineis = () => {
  const navigate = useNavigate();
  const { modulo } = useParams();
  const { sessao } = useContext(SessaoUsuarioContext);
  const { ambiente: { paineis, ambienteDeDadosCriado } } = useContext(AppContext);
  const [background, setBackground] = useState("");

  const paineisParaExibir = useMemo(() => {
    
    const paineisModulo = modulo === Modulos.Pessoal ? paineisPessoal : (modulo === Modulos.Rh) ? paineisRh : [];
    const hasCustosDaEmpresa = paineis?.find(painel => painel.nome?.toLowerCase() === "custos da empresa");
    const paineisOrdenados = paineisModulo
      .map((x) => paineis?.find(f => f.nome?.toLowerCase() === x.toLowerCase()))
      .filter(painel => painel !== undefined && painel.nome?.toLowerCase() !== (hasCustosDaEmpresa?.id !==  undefined ? "gastos da empresa" : "")) as [];
    
    return paineisOrdenados as Painel[];
  }, [paineis, modulo]);

  const exibeNotificacao = useMemo(() => {
    return sessao && !sessao.modulosLiberados.some(liberado => modulo?.toLowerCase() === liberado.nome.toLowerCase());
  }, [modulo, sessao])

  useEffect(() => {
    const homeElement = document.querySelector('.lista-de-paineis_background') as HTMLElement
    if (homeElement) {
      setBackground(`/modulo-${modulo}.png`);
    }

  }, [modulo]);

  useEffect(() => {

    if (ambienteDeDadosCriado === false) {
      navigate('/atualizar');
    }
  }, [ambienteDeDadosCriado, navigate]);

  return (
    <>
      <h1 className="top-title"><Voltar />Lista de Paineis</h1>
      <InfoNovaVersao modulo={modulo} />
      {exibeNotificacao ? <NotificacaoDeAcessoNegadoAoModulo /> : <></>}
      <div className='painel'>
        <div className='painel__lista__row'>
          <div className='painel__lista__column'>
            <div className='paineis'>
              {!paineis?.length && <>
                <div className='painel__loading'>
                  <Spinner size={170} />
                </div>
              </>}
              {paineisParaExibir?.map(painel => (
                <Link to={`/painel/${modulo}/${painel.nome}`} key={painel.id}>
                  <div className='painel__link-painel'>
                    <div>
                      <img src={`/${painel.nome}.png`} alt='' />
                      <label>{painel.nome}</label>
                    </div>
                  </div>
                </Link>
              ))}
            </div>

          </div>
          <div className='painel__lista__column'>
            <img className='lista-de-paineis_background' alt='' src={background} />
          </div>
        </div>

      </div>
    </>
  );
};

export default ListaDePaineis;