import { Tickets } from "../models/Ticket";
import FBIHttp from "./FBIHttp";

export interface ObtencaoDeTicketsOutput extends Tickets{}

class MovideskService {
  private static readonly baseUrl = '/api/bi/Movidesk';

  public static ObtemListaDeSolicitacao = async (): Promise<any> => FBIHttp.get(`${this.baseUrl}/`);

  public static ObtemListaDeSolicitantes = async (valor?: string | null): Promise<any> => FBIHttp.get(`${this.baseUrl}/usuarios?input=${valor}`);

  public static CriaSolicitacao = async (input: any): Promise<ObtencaoDeTicketsOutput> => FBIHttp.post(this.baseUrl, input);
  
  public static ReprocessaSugestoes = async (): Promise<ObtencaoDeTicketsOutput> => FBIHttp.post(`${this.baseUrl}/sincronizar`);
}

export default MovideskService;